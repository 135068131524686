<template>
  <span>
    <span>{{ $t('discounts.discounts.' + feeType) }}</span> /

       <span class="text-main" v-if="feeType==='%'">{{ fee }} %</span>
    <span class="text-main" v-else>{{ formatNumber(fee) }}</span>

  </span>
</template>

<script>
export default {
  name: "checkFee",
  props: {
    fee: null,
    feeType: '',
  }
}
</script>

<style scoped>

</style>
