<template>
  <validation-observer
      #default="{ invalid }"
  >

    <b-modal
        v-model="show"
        content-class="package-modal rounded-8"
        dialog-class="wameed-modal-md"
        no-fade
        :key="key"
    >
      <template slot="modal-header-close">
        <close-icon/>
      </template>
      <template slot="modal-title">
        <div class="d-block text-center">
          <h3 class="text-reg-18">
            {{ modalData != null ? $t('settings.fees.update') : $t('settings.fees.new') }}
          </h3>
        </div>
      </template>


      <b-row>
        <b-col md="6">
          <wameed-dropdown
              v-model="form.type_obj"
              :label="$t('settings.fees.type')"
              :placeholder="$t('discounts.discounts.placeholder')"
              variant="disable"
              :items="discountTypes"
              title="name"
              class="wameed_dropdown"
              rules="required"
              @input="updateModal"
              :no_options="$t('common.no_options')"
          />
        </b-col>


        <b-col md="6">
          <TextInput
              v-model="form.value"
              rules="required"
              field-classes="w-100"
              :label="$t('settings.fees.value')"
              :placeholder="$t('form.text.placeholder')"
              is-append
              :append-text="getValueAppendText"
              type="number"
          />
        </b-col>

        <b-col md="12">
          <wameed-dropdown
              v-model="form.vendor"
              :label="$t('discounts.form.apply_for')"
              :placeholder="$t('form.text.placeholder')"
              variant="disable"
              :items="getApplyFor"
              title="name"
              class="wameed_dropdown"
              rules="required"
              :searchable="true"
              :no_options="$t('common.no_options')"
              :loadingMore="$t('common.loadingMore')"
              :no_options_search="$t('common.no_options_search')"
              @input="updateModal"
          />
        </b-col>
      </b-row>

      <template slot="modal-footer">
        <wameed-btn
            classes="  text-med-14 text-white mx-0 rounded-8"
            :title="modalData!=null ?$t('btn.update'):$t('btn.add')"
            :disabled="invalid"
            type="submit"
            variant="main"
            @onClick="submitOrder()"
        />
        <wameed-btn
            classes="  text-med-14 text-font-secondary mx-0 rounded-8 "
            :title="$t('btn.cancel')"
            type="button"
            variant="gray"
            @onClick="closeModal()"
        />
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import {WameedBtn, WameedDropdown, WameedTextInput as TextInput} from 'wameed-ui/dist/wameed-ui.esm';
import {mapActions, mapGetters} from 'vuex';

import {DiscountTypes} from "@/enums/discountType.enum";
import i18n from "@/libs/i18n";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    WameedDropdown,
    WameedBtn,
    TextInput,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    modalData: {
      type: Object,
      default: null,
    },

  },

  data() {
    return {
      key: 1,
      selectAll: false,
      discountTypes: [
        {
          id: '%',
          name: this.$t('discounts.discounts.%'),
        },
        {
          id: 'v',
          name: this.$t('discounts.discounts.v'),
        },

      ],
      form: {
        value: '',
        type_obj: '',
        vendor: '',
      },
      showSuccessModal: false,
    };
  },
  computed: {

    ...mapGetters({
      getApplyFor: 'admin/fees/getApplyFor',
    }),


    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },


    getValueAppendText() {
      let temp = "";
      if (this.form.type_obj) {
        temp = this.form.type_obj.id;
      } else if (this.form.type_id) {
        temp = this.form.type_id
      }
      if (temp) {
        return this.$i18n.t('discounts.discounts.append_' + temp)
      }
      return this.$i18n.t('discounts.discounts.append_text')
    }
  },
  watch: {
    modalData(newVal) {
      if (newVal != null) {
        this.form.id = this.modalData.id;
        this.form.value = this.modalData.value;
        this.form.vendor = this.modalData.vendor;
        this.form.type_obj = this.discountTypes.find((data) => data.id === this.modalData.type);
      } else {
        this.form.id = '';
        this.form.value = '';
        this.form.vendor = '';
        this.form.type_obj = '';
      }
    },
  },
  created() {

    if (this.modalData != null) {
      this.form.id = this.modalData.id;
      this.form.value = this.modalData.value;
      this.form.vendor = this.modalData.vendor;
      this.form.type_obj = this.discountTypes.find((data) => data.id === this.modalData.type);

    }
  },
  methods: {
    ...mapActions({
      create: "admin/fees/create",
      update: "admin/fees/update",
    }),

    submitOrder() {
      this.form.type = this.form.type_obj.id;
      this.form.vendor_id = this.form.vendor.id;
      if (this.modalData == null) {
        this.create(this.form).then(() => {
          this.$emit('onComplete', true);
          this.closeModal();
          this.$store.commit('loadingFinish', null, {root: true});
        });
      } else {
        this.update(this.form).then(() => {
          this.$emit('onComplete', true);
          this.closeModal();
          this.$store.commit('loadingFinish', null, {root: true});
        });
      }
    },
    updateModal(v) {
      this.key += 1;
    },

    closeModal() {
      this.form = {
        value: '',
        type_obj: '',
        type_id: '',
        vendor: '',
      }
      this.show = false;
    },

  },
};
</script>
